import React, { useRef } from "react";
import emailjs from '@emailjs/browser';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

export default function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_5jjlwlp', 'template_fs7b6c7', form.current, 'opw8vT3Z8sEy0Yb3B')
      .then((result) => {
          alert("Votre email a bien été envoyé ! 👍");
      }, (error) => {
          alert("Une erreur est survenue, impossible d'envoyer votre email ❌");
      });
  };

  const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    arrows: true,
  }
  return (
    <section id="Contact" className="contact--section">
      <div>
        <h2>📧 Contactez-nous</h2>
        <p className="text-lg">
          Vous avez besoin de quelconques renseignements sur divers sujets, c'est par ici 👇
        </p>
      </div>
      <form ref={form} id="contactForm" name="contactForm" className="contact--form--container" onSubmit={sendEmail}>
        <div className="container">
          <label htmlFor="first-name" className="contact--label">
            <span className="text-md">Prénom *</span>
            <input
              type="text"
              className="contact--input text-md"
              name="first-name"
              id="first-name"
              required
              maxLength="50"
            />
          </label>
          <label htmlFor="last-name" className="contact--label">
            <span className="text-md">Nom *</span>
            <input
              type="text"
              className="contact--input text-md"
              name="last-name"
              id="last-name"
              required
              maxLength="50"
            />
          </label>
          <label htmlFor="email" className="contact--label">
            <span className="text-md">Email *</span>
            <input
              type="email"
              className="contact--input text-md"
              name="email"
              id="email"
              required
            />
          </label>
          <label htmlFor="phone" className="contact--label">
            <span className="text-md">Téléphone *</span>
            <input
              type="phone"
              className="contact--input text-md"
              name="phone"
              id="phone"
              required
              maxLength="10"
            />
          </label>
        </div>
        <label htmlFor="topic" className="contact--label">
          <span className="text-md">Objet du message</span>
          <select id="topic" type="topic" name="topic" className="contact--input text-md">
            <option>Inscription</option>
            <option>Demande de renseignements</option>
            <option>Autre demande</option>
          </select>
        </label>
        <label htmlFor="message" className="contact--label">
          <span className="text-md">Message</span>
          <textarea
            className="contact--input text-md"
            id="message"
            rows="8"
            type="message" 
            name="message"
            placeholder="Écrivez votre message ici..."
          />
        </label>
        <div>
          <button type="submit" value="Send" className="btn btn-primary contact--form--btn">Envoyer mon message</button>
        </div>
      </form>
      <br />
      <div>
        <h2>📸 Notre galerie photo</h2>
      </div>
      <div className="slideShow-width">
        <Slide {...properties} easing="ease" style={{width: '100%', display: 'block', position: 'relative', marginLeft: 'auto', marginRight: 'auto', textAlign: 'center'}}>
          <div className="each-slide">
            <div className="bp__header-image-mobile">
              <img src="./wallpaper/moto.jpg" className="border-r" alt="Slide" />
            </div>
          </div>
          <div className="each-slide">
            <div className="bp__header-image-mobile">
              <img src="./wallpaper/remorque.jpg" className="border-r" alt="Slide" />
            </div>
          </div>
          <div className="each-slide">
            <div className="bp__header-image-mobile">
              <img src="./wallpaper/vehicule.jpg" className="border-r" alt="Slide" />
            </div>
          </div>
        </Slide>
      </div>
      <br />
      <div>
        <h2>🎬 Notre galerie vidéo</h2>
      </div>
      <div className="slideShow-width">
        <Slide {...properties} easing="ease" style={{width: '100%', display: 'block', position: 'relative', marginLeft: 'auto', marginRight: 'auto', textAlign: 'center'}}>
          <div className="each-slide">
            <div className="bp__header-image-mobile">
            <iframe
              src="https://www.youtube.com/embed/Ck1dWCd6Vlo"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="border-r"
              style={{width: '100%', height: '25rem'}}
            ></iframe>
            </div>
          </div>
          <div className="each-slide">
            <div className="bp__header-image-mobile">
            <iframe
              src="https://www.youtube.com/embed/3rd4FfnHVnk"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="border-r"
              style={{width: '100%', height: '25rem'}}
            ></iframe>
            </div>
          </div>
          <div className="each-slide">
            <div className="bp__header-image-mobile">
            <iframe
              src="https://www.youtube.com/embed/-4v7UAJk0Hk"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="border-r"
              style={{width: '100%', height: '25rem'}}
            ></iframe>
            </div>
          </div>
        </Slide>
      </div>
      <p>Toute l'équipe de l'Auto-Moto école Directt Permis vous remercie de votre confiance et vous souhaite bonne route ! ✌️</p>
    </section>
  );
}
